<script lang="ts" setup>
import { shallowRef, ref } from "vue"
import { useRoute, useRouter } from "vue-router"

import { getChildById } from "@/services/Child"
import {
  getGrowthAssessmentForChild,
  getRelevantGrowthMeasurements,
  createOrReplaceGrowthAssessment,
  finalizeGrowthAssessment,
  isChildOlderThan2,
} from "@/services/GrowthAssessment"
import { setCurrentViewContext } from "@/utils/GlobalState"
import GrowthMeasurementNavItem from "@/components/assessments/GrowthMeasurementNavItem.vue"
import OfflineEnabledForm from "@/components/OfflineEnabledForm.vue"
import ChildHeader from "@/components/children/ChildHeader.vue"

import HeadCircumferenceExample from "@/components/svg/HeadCircumferenceExample.vue"
import HeightProfileExample from "@/components/svg/HeightProfileExample.vue"
import LengthExample from "@/components/svg/LengthExample.vue"
import ArmSizeExample3 from "@/components/svg/ArmSizeExample3.vue"
import WeightAdultChildExample from "@/components/svg/WeightAdultChildExample.vue"
import WeightChildExample from "@/components/svg/WeightChildExample.vue"
import WeightInfantExample from "@/components/svg/WeightInfantExample.vue"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext
const route = useRoute()
const child = ref(null)
const isReady = ref(false)
const measurementsNeeded = ref([])
const weightImage = shallowRef(null)
const weightImageIsTall = ref(false)
const childId = parseInt(route.params.childId as string)
const assessmentId = parseInt(route.params.assessmentId as string)

const growthAssessment = ref(null)
const weightUrlParams = { name: "MeasurementWeight", params: route.params }
const lengthUrlParams = { name: "MeasurementLength", params: route.params }
const headSizeUrlParams = { name: "MeasurementHeadSize", params: route.params }
const muacUrlParams = { name: "MeasurementMuac", params: route.params }
const router = useRouter()

async function getData() {
  child.value = await getChildById(childId)
  growthAssessment.value = await getGrowthAssessmentForChild(assessmentId)
  measurementsNeeded.value = getRelevantGrowthMeasurements(child.value, growthAssessment.value)
  if (isChildOlderThan2(child.value.dob, growthAssessment.value.dateOfAssessment)) {
    weightImage.value = growthAssessment.value.canChildStand ? WeightChildExample : WeightAdultChildExample
    weightImageIsTall.value = true
  } else {
    weightImage.value = WeightInfantExample
    weightImageIsTall.value = false
  }
  setCurrentViewContext(child.value, "child")
  isReady.value = true
}

async function localSaveFunc() {
  growthAssessment.value.isComplete = true
  await createOrReplaceGrowthAssessment(child.value, growthAssessment.value)
  await finalizeGrowthAssessment(child.value, growthAssessment.value)
  return assessmentId
}

async function pushSuccessRoute() {
  router.push({ name: "GrowthRecommendations", params: { assessmentId, childId } })
}

getData()
</script>

<template>
  <article>
    <OfflineEnabledForm
      v-if="isReady"
      :page-title="$gettext('Growth')"
      :schema="[]"
      :local-save-func="localSaveFunc"
      :push-success-route-func="pushSuccessRoute"
      :form-is-enabled="Boolean(growthAssessment.muacInCm || growthAssessment.weightInKilograms)"
    >
      <template #header>
        <ChildHeader
          v-if="child"
          :child="child"
        />
      </template>

      <template #formPreface>
        <div class="block">
          <p>
            {{
              $gettext(
                "Based on this child's age and abilities, the following measurements are recommended.",
              )
            }}
          </p>
          <p>{{ $gettext("Tap on each one to record a measurement.") }}</p>
        </div>

        <nav class="growth-measurements block">
          <div class="columns is-mobile">
            <!-- Weight -->
            <GrowthMeasurementNavItem
              v-if="measurementsNeeded.includes('weight')"
              link-class="weight-panel"
              :label="$gettext('Weight')"
              :image-component="weightImage"
              :is-complete="Boolean(growthAssessment.weightInKilograms)"
              :link-params="weightUrlParams"
              :tall-image="weightImageIsTall"
            />

            <!-- Height -->
            <GrowthMeasurementNavItem
              v-if="measurementsNeeded.includes('height')"
              link-class="height-panel"
              :label="$gettext('Height')"
              :image-component="HeightProfileExample"
              :is-complete="Boolean(growthAssessment.lengthInCm)"
              :link-params="lengthUrlParams"
              :tall-image="true"
            />

            <!-- Length -->
            <GrowthMeasurementNavItem
              v-if="measurementsNeeded.includes('length')"
              link-class="length-panel"
              :label="$gettext('Length')"
              :image-component="LengthExample"
              :is-complete="Boolean(growthAssessment.lengthInCm)"
              :link-params="lengthUrlParams"
            />
          </div>
          <div class="block">
            <!-- Temporary spacer -->
          </div>
          <div class="columns is-mobile">
            <!-- Head size -->
            <GrowthMeasurementNavItem
              v-if="measurementsNeeded.includes('head')"
              link-class="head-size-panel"
              :label="$gettext('Head Size')"
              :image-component="HeadCircumferenceExample"
              :is-complete="Boolean(growthAssessment.headCircumferenceInCm)"
              :link-params="headSizeUrlParams"
            />

            <!-- MUAC -->
            <GrowthMeasurementNavItem
              v-if="measurementsNeeded.includes('muac')"
              link-class="arm-size-panel"
              :label="$gettext('Arm Size')"
              :image-component="ArmSizeExample3"
              :is-complete="Boolean(growthAssessment.muacInCm)"
              :link-params="muacUrlParams"
            />
          </div>
        </nav>
      </template>
    </OfflineEnabledForm>
  </article>
</template>
